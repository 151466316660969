import { useEffect, useState } from "react";
import axios from "axios";
import { liabilityController } from "../vehicle-insurance-routes";
import { InsurancePackageWithCalculationsDto } from "../../../dto/insurance/InsurancePackageWithCalculationsDto";

export const useGetLiabilityCalculations = () => {
  const [insurancePackageRequestId, setInsurancePackageRequestId] = useState<
    number | null
  >(null);
  const [
    insurancePackageWithCalculationsDto,
    setInsurancePackageWithCalculationsDto,
  ] = useState<InsurancePackageWithCalculationsDto>();
  const [isSpinner, setIsSpinner] = useState<boolean>(false);

  useEffect(() => {
    if (!insurancePackageRequestId) return;
    setIsSpinner(true);

    const getInsurancePackageWithCalculations = async () => {
      try {
        const response = (
          await axios.get(`${liabilityController}/${insurancePackageRequestId}`)
        ).data as InsurancePackageWithCalculationsDto;
        setInsurancePackageWithCalculationsDto(response);
      } catch (e) {
        console.error(e.response);
      } finally {
        setIsSpinner(false);
      }
    };
    getInsurancePackageWithCalculations();
  }, [insurancePackageRequestId]);

  return {
    insurancePackageWithCalculationsDto,
    setInsurancePackageRequestId,
    isSpinner,
  };
};
