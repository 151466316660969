import React, { ReactElement } from "react"

export const SvgYearsOld = (): ReactElement => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    style={{ backgroundColor: "transparent" }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.3432 14.4544C14.3126 12.4956 13.7024 10.7145 12.6174 9.42501C11.5387 8.14333 10.0768 7.4375 8.50062 7.4375C6.92441 7.4375 5.46254 8.14333 4.38387 9.42501C3.29992 10.7132 2.69008 12.4918 2.6582 14.4481C3.27113 14.7552 5.80752 15.9375 8.50062 15.9375C11.4128 15.9375 13.7748 14.7621 14.3432 14.4544Z"
      fill="#308FFF"
    />
    <path
      d="M8.5 6.90625C10.1137 6.90625 11.4219 5.59808 11.4219 3.98438C11.4219 2.37067 10.1137 1.0625 8.5 1.0625C6.88629 1.0625 5.57812 2.37067 5.57812 3.98438C5.57812 5.59808 6.88629 6.90625 8.5 6.90625Z"
      fill="#308FFF"
    />
  </svg>
)
