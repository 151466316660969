import { AxiosResponse } from "axios";
import { admin, login } from "./routes";

export const axios = require("axios");

axios.interceptors.request.use(
  (req: { headers: { authorization: string } }) => {
    const acesToken = localStorage.getItem("Bearer");
    req.headers.authorization = acesToken ? "Bearer " + acesToken : "";
    return req;
  },
);

axios.interceptors.response.use(
  (resp: AxiosResponse) => resp,
  (error: any) => {
    if ({ ...error }?.response?.status === 401) {
      localStorage.removeItem("Bearer");
      window.location.href = "#" + admin + login;
    } else {
      throw error;
    }
  },
);
