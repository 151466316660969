import { useEffect, useState } from "react";
import { axios } from "../axios";
import { brandRoute, vehicleRoute } from "../routes";
import { CONNECTION_HOST } from "../environment-variables";
import { VehicleBrandDto } from "../dto/VehicleBrandDto";

export const useGetVehicleBrands = (): VehicleBrandDto[] => {
  const [brands, setBrands] = useState<VehicleBrandDto[]>([]);

  useEffect(() => {
    const getVehicles = async () => {
      try {
        let resp = await axios.get(CONNECTION_HOST + vehicleRoute + brandRoute);
        setBrands(resp.data);
      } catch (e) {
        console.error(e);
      }
    };
    getVehicles();
  }, []);
  return brands;
};
