import React, { ReactElement } from "react"

export const SvgPerson = (): ReactElement => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.656 12.7539C12.629 11.0256 12.0905 9.45401 11.1332 8.31618C10.1814 7.18529 8.89156 6.5625 7.50078 6.5625C6.11 6.5625 4.82012 7.18529 3.86835 8.31618C2.91192 9.45281 2.37383 11.0222 2.3457 12.7484C2.88652 13.0193 5.12451 14.0625 7.50078 14.0625C10.0704 14.0625 12.1544 13.0254 12.656 12.7539Z"
      fill="#565656"
    />
    <path
      d="M7.5 6.09375C8.92386 6.09375 10.0781 4.93948 10.0781 3.51562C10.0781 2.09177 8.92386 0.9375 7.5 0.9375C6.07614 0.9375 4.92188 2.09177 4.92188 3.51562C4.92188 4.93948 6.07614 6.09375 7.5 6.09375Z"
      fill="#565656"
    />
  </svg>
)
