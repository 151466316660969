import React, { ReactElement } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import { TypeInsuranceEnum } from "../../core/enums";
import get from "lodash/get";
import { theme } from "../../theme/theme";
import { Box, BoxProps } from "../box";
import { TariffCard } from "../tariff-card";
import { Spinner } from "../spinner";
import { CompanyTariff, InsuranceVariant } from "../../core";

import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import { getTariffFields } from "../../tariffs-data";
import { InsurancePackageWithCalculationsDto } from "../../dto/insurance/InsurancePackageWithCalculationsDto";
import { CalculationCardDto } from "../../dto/insurance/calculation/CalculatuinCardDto";

type Props = BoxProps & {
  tariffs?: CompanyTariff[];
  typeInsurance: TypeInsuranceEnum;
  minHeight?: string | string[];
  selectTariff?: (
    total: number,
    companyName: string,
    variant: InsuranceVariant,
  ) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deductible?: any;
  isUpdating?: boolean;
  setIsUpdating?: (isUpdating: boolean) => void;
  insurancePackageWithCalculationsDto?: InsurancePackageWithCalculationsDto;
  isSpinner?: boolean;
};

export const TariffCards = ({
  tariffs,
  minHeight,
  typeInsurance,
  selectTariff,
  deductible,
  isUpdating,
  setIsUpdating,
  insurancePackageWithCalculationsDto,
  isSpinner,
  ...rest
}: Props): ReactElement => {
  const isMobile = useMediaQuery({
    maxWidth: theme("breakpoints.3"),
  });

  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const locationState: any = location.state || {};
  const {
    civilLiabilityInsurance,
    voluntaryLiabilityInsurance,
    personalInsurance,
  } = locationState;
  return isSpinner ? (
    <Spinner />
  ) : (
    <Box
      display="flex"
      mx={isMobile ? "26px" : "180px"}
      justifyContent="center"
      flexWrap="wrap"
      {...rest}
    >
      {tariffs?.map((tariff: CompanyTariff, index: number) => {
        const { image, rating, name } = tariff;
        const fields = getTariffFields({
          typeInsurance,

          additionalField: {
            carLiability: civilLiabilityInsurance,
            voluntaryLiabilityInsurance: get(
              voluntaryLiabilityInsurance,
              "voluntaryLiabilityCoverage",
              false,
            ),
            personalInsurance: personalInsurance?.personalInsuranceCoverage,
          },
          deductible,
        });

        const total = 200;

        return (
          <TariffCard
            key={index}
            imagePath={String(image)}
            rating={Number(rating)}
            fields={fields}
            total={total}
            company={name}
            typeInsurance={typeInsurance}
            minHeight={minHeight}
            selectTariff={selectTariff}
          />
        );
      })}
      {insurancePackageWithCalculationsDto?.calculationCardList.map(
        (calculationCardDto: CalculationCardDto, index: number) => {
          const fieldsLiability = getTariffFields({
            typeInsurance,
            liabilityData: calculationCardDto.calculation,
          });
          return (
            <TariffCard
              key={index}
              imagePath={calculationCardDto.company.image}
              rating={calculationCardDto.company.rating}
              fields={fieldsLiability}
              total={calculationCardDto.calculation.premium}
              company={calculationCardDto.company.name}
              typeInsurance={typeInsurance}
              minHeight={minHeight}
              selectTariff={selectTariff}
            />
          );
        },
      )}
    </Box>
  );
};
