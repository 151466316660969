export enum InsuranceType {
  CAR_CAR = "CAR_CAR",
  CAR_CCL = "CAR_CCL",
  CAR_VCL = "CAR_VCL",
  CAR_CPA = "CAR_CPA",
  HEALTH_INBOUND = "HEALTH_INBOUND",
  HEALTH_OUTBOUND = "HEALTH_OUTBOUND",
}

export enum PurposeType {
  PERSONAL = "PERSONAL",
  PICKUP = "PICKUP",
  TAXI = "TAXI",
  COMMERCIAL = "COMMERCIAL",
  TRUCK = "TRUCK",
}
