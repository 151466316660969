import React, { ReactElement } from "react"
import { Formik, FormikProps, FormikValues, FormikHelpers } from "formik"
import { useLocation, useHistory } from "react-router-dom"
import { t } from "../../i18n/i18n"

import { FormInfoSchema } from "../../validation-schema/comprehensive-car-info.schema"
import { ComprehensiveCarInfoView } from "./comprehensive-car-info.view"
import { comprehensiveCarPrice } from "../routes"
import { gtagMPDFormInfo } from "../../core/gtag"
import { getPhoneNumber } from "../../core/utils"

export interface InfoFormType {
  owner: string
  number: string
  phone: string
}

export const ComprehensiveCarInfoForm = (): ReactElement => {
  const location = useLocation()
  const state: any = location.state

  const history = useHistory()

  const initalValues = {
    owner: state && state.owner ? state.owner : "",
    number: state && state.number ? state.number : "",
    phone: state && state.phone ? state.phone : "",
  }

  const handleSumbit = (
    values: FormikValues,
    actions: FormikHelpers<any>,
  ): void => {
    gtagMPDFormInfo()

    const phone = getPhoneNumber(values.phone)
    if (phone.length < 11) {
      actions.setFieldError("phone", t("general.phoneLengthError"))
      actions.setSubmitting(false)

      return
    }

    history.push({
      pathname: comprehensiveCarPrice,
      state: { ...state, ...values },
    })
  }

  return (
    <Formik
      onSubmit={handleSumbit}
      initialValues={initalValues}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={FormInfoSchema}
    >
      {(props: FormikProps<InfoFormType>): ReactElement => (
        <ComprehensiveCarInfoView {...props} />
      )}
    </Formik>
  )
}
