import {
  VehicleCivilLiabilityDto,
  VehicleFullLiabilityDto,
  VehiclePersonalAccidentDto,
  VehicleVoluntaryCivilLiabilityDto,
} from "../../dto/insurance/VehicleInsuranceDtos";
import { VehicleFullLiabilityParams } from "../../entities/vehicle.insurance/VehicleFullLiabilityParams";

//TODO rework this
export default function toVehicleLiabilityConverter(
  params: VehicleFullLiabilityParams,
): VehicleFullLiabilityDto {
  const purpose = params.vehicleCivilLiabilityParams.purpose;
  const seats = params.vehicleCivilLiabilityParams.seats
    ? parseInt(params.vehicleCivilLiabilityParams.seats)
    : 0;
  const isTractor = params.vehicleCivilLiabilityParams.purpose === "Tractor";
  const tonnageFrom = 3;
  const tonnageTo = 8;
  const vehicleCivilLiabilityDto: VehicleCivilLiabilityDto = {
    purpose,
    seats,
    isTractor,
    tonnageFrom,
    tonnageTo,
  };

  const vehicleVoluntaryCivilLiabilityDto: VehicleVoluntaryCivilLiabilityDto = {
    purpose,
    seats,
    isTractor,
    tonnageFrom,
    tonnageTo,
    coverage: params.vehicleVoluntaryCivilLiabilityParams
      .voluntaryLiabilityCoverage
      ? parseInt(
          params.vehicleVoluntaryCivilLiabilityParams
            .voluntaryLiabilityCoverage,
        )
      : 0,
  };

  const vehiclePersonalAccidentDto: VehiclePersonalAccidentDto = {
    coverage: params.vehiclePersonalAccidentParams.personalInsuranceCoverage
      ? parseInt(params.vehiclePersonalAccidentParams.personalInsuranceCoverage)
      : 0,
    passengers: params.vehiclePersonalAccidentParams.personalNumber
      ? parseInt(params.vehiclePersonalAccidentParams.personalNumber)
      : 0,
  };

  return {
    vehicleCivilLiabilityDto,
    vehicleVoluntaryCivilLiabilityDto,
    vehiclePersonalAccidentDto,
  };
}
