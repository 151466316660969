import React, { ReactElement, useState } from "react";
import { Form } from "formik";
import { Link } from "react-router-dom";
import { getImagePath } from "../hooks/getImagePath";
import { Button, makeStyles } from "@material-ui/core";
import { t } from "../i18n/i18n";
import { useLogin } from "../hooks/use-login";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  textField: {
    marginTop: theme.spacing(2),
    width: "24vmax",
    minWidth: "250px",
    height: "5vh",
  },
  button: {
    marginTop: theme.spacing(2),
    width: "24vmax",
    minWidth: "250px",
    variant: "contained",
    size: "large",
  },
  logoImage: {
    width: "24vmax",
    marginBottom: "3rem",
  },
  link: {
    marginTop: theme.spacing(1),
  },
}));

interface Props {}

export const LoginFormContent = (props: Props): ReactElement => {
  const { logIn } = useLogin();
  const classes = useStyles();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  function handleLogin() {
    logIn({
      email: email,
      password: password,
    });
  }

  return (
    <Form>
      <div className={classes.container}>
        <img
          className={classes.logoImage}
          src={getImagePath("sorry.png")}
          alt=""
        />

        <input
          className={classes.textField}
          name={"email"}
          placeholder={t("general.email")}
          onChange={event => setEmail(event.target.value)}
        />
        <input
          className={classes.textField}
          name={"password"}
          type={"password"}
          placeholder={t("general.password")}
          onChange={event => setPassword(event.target.value)}
        />

        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={() => handleLogin()}
        >
          {t("general.logIn")}
        </Button>
        <Link className={classes.link} to="/forgot-password">
          {t("general.forgotPassword")}
        </Link>
      </div>
    </Form>
  );
};
