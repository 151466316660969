import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { t } from "../i18n/i18n";
import { UserDto } from "../dto/UserDto";
import { Role } from "../dto/Role";

export function UserPopup(props: {
  isPopupOpen: boolean;
  savePopup: (userDto: UserDto) => void;
  closePopup: () => void;
  user: UserDto | null;
}) {
  const { isPopupOpen, savePopup, closePopup, user } = props;

  const defaultRole = Role.CONTACT_CENTER;

  const [userId, setUserId] = useState<number | undefined>(undefined);
  const [email, setEmail] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [phone, setPhone] = useState<number | null>(null);
  const [role, setRole] = useState<Role>(defaultRole);
  const [password, setPassword] = useState<string>("");

  const [isFirstNameInputInvalid, setIsFirstNameInputInvalid] = useState(false);
  const [isLastNameInputInvalid, setIsLastNameInputInvalid] = useState(false);
  const [isPasswordInputInvalid, setIsPasswordInputInvalid] = useState(false);
  const [isEmailInputInvalid, setIsEmailInputInvalid] = useState(false);

  const ROLES: Role[] = Object.keys(Role) as Role[];

  useEffect(() => {
    setUserId(user ? user.id : undefined);
    setEmail(user ? user.email : "");
    setFirstName(user ? user.firstName : "");
    setLastName(user ? user.lastName : "");
    setPhone(user ? user.phone : null);
    setRole(user ? user.role : defaultRole);
  }, [user]);

  const validateFirstName = () => {
    if (!firstName) {
      setIsFirstNameInputInvalid(true);
      return true;
    }
    setIsFirstNameInputInvalid(false);
    return false;
  };

  const validateLastName = () => {
    if (!lastName) {
      setIsLastNameInputInvalid(true);
      return true;
    }
    setIsLastNameInputInvalid(false);
    return false;
  };

  const validatePassword = () => {
    if (!userId && !password) {
      setIsPasswordInputInvalid(true);
      return true;
    }
    setIsPasswordInputInvalid(false);
    return false;
  };

  const validateEmail = () => {
    if (!/^[.a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email)) {
      setIsEmailInputInvalid(true);
      return true;
    }
    setIsEmailInputInvalid(false);
    return false;
  };

  const handleCancel = () => {
    setUserId(undefined);
    setEmail("");
    setFirstName("");
    setLastName("");
    setPhone(null);
    setRole(defaultRole);
    setPassword("");

    setIsFirstNameInputInvalid(false);
    setIsLastNameInputInvalid(false);
    setIsPasswordInputInvalid(false);
    setIsEmailInputInvalid(false);

    closePopup();
  };

  const handleSave = () => {
    const isFirstNameInvalid=validateFirstName();
    const isLastNameInvalid=validateLastName();
    const isEmailInvalid=validateEmail();
    const isPasswordInvalid=validatePassword();
    if (
      !isFirstNameInvalid &&
      !isLastNameInvalid &&
      !isEmailInvalid &&
      (userId || !isPasswordInvalid)
    ) {
      const newUser: UserDto = {
        id: userId,
        email: email,
        password: password,
        firstName: firstName,
        lastName: lastName,
        phone: phone ? phone : 0,
        role: role,
      };
      savePopup(newUser);
      handleCancel();
    }
  };

  return (
    <Dialog open={isPopupOpen} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {!userId ? t("userPopUp.addToCreateTitle") : t("userPopUp.editTitle")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {!userId ? t("userPopUp.fillToCreate") : t("userPopUp.editContent")}
        </DialogContentText>
        <TextField
          fullWidth
          label={t("general.email")}
          defaultValue={email}
          required={true}
          error={isEmailInputInvalid}
          helperText={isEmailInputInvalid && t("userPopUp.inputCorrectEmail")}
          onChange={event => setEmail(event.target.value)}
        />
        <TextField
          fullWidth
          label={t("general.firstName")}
          defaultValue={firstName}
          required={true}
          error={isFirstNameInputInvalid}
          helperText={
            isFirstNameInputInvalid && t("userPopUp.fullNameRequired")
          }
          onChange={event => setFirstName(event.target.value)}
        />
        <TextField
          fullWidth
          label={t("general.lastName")}
          defaultValue={lastName}
          required={true}
          error={isLastNameInputInvalid}
          helperText={isLastNameInputInvalid && t("userPopUp.fullNameRequired")}
          onChange={event => setLastName(event.target.value)}
        />
        <TextField
          fullWidth
          id="phoneNumber"
          margin="dense"
          label={t("general.phone")}
          type="phone"
          defaultValue={phone}
          onChange={event => setPhone(parseInt(event.target.value, 10) || null)}
        />
        <InputLabel id="role-label">Role</InputLabel>
        <Select
          fullWidth
          labelId={"role-label"}
          defaultValue={role}
          onChange={e => setRole(e.target.value as Role)}
        >
          {ROLES.map(role => (
            <MenuItem key={role} value={role}>
              {t("roles." + role)}
            </MenuItem>
          ))}
        </Select>
        <TextField
          fullWidth
          label={t("general.password")}
          defaultValue={password}
          required={!userId}
          error={isPasswordInputInvalid}
          helperText={isPasswordInputInvalid && t("userPopUp.passwordRequired")}
          onChange={event => setPassword(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          {t("general.cancel")}
        </Button>
        <Button onClick={handleSave} color="primary">
          {!userId ? t("general.create") : t("general.edit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
