export const textStyles = {
  title: {
    fontWeight: 900,
  },
  primary: {
    fontWeight: 600,
  },
  normal: {
    fontWeight: "normal",
  },
}
