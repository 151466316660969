import { useEffect, useState } from "react";
import { admin, vehicleRoute } from "../routes";
import { axios } from "../axios";
import { VehicleDto } from "../dto/VehicleDto";
import { CONNECTION_HOST } from "../environment-variables";

export const useSaveVehicle = () => {
  const [error, setError] = useState<{ message: string } | null>(null);
  const [vehicle, setVehicle] = useState<VehicleDto | null>(null);
  const [updatedVehicle, setUpdatedVehicle] = useState<VehicleDto | null>(null);
  const [isSaving, setIsSaving] = useState<boolean >(false);

  useEffect(() => {
    if (!vehicle) return;
    const saveVehicle = async (vehicle: VehicleDto) => {
      setIsSaving(true);
      try {
        let resp;
        if (vehicle.id) {
          resp = await axios.put(
            CONNECTION_HOST + admin + vehicleRoute,
            vehicle,
          );
        } else {
          resp = await axios.post(
            CONNECTION_HOST + admin + vehicleRoute,
            vehicle,
          );
        }
        setUpdatedVehicle(resp.data);
      } catch (e) {
        setError(e);
        console.error(e);
      } finally {
        setIsSaving(false);
      }
    };
    saveVehicle(vehicle);
  }, [vehicle]);

  return {
    isSaving,
    error,
    updatedVehicle,
    saveVehicle: (vehicle: VehicleDto) => setVehicle(vehicle),
  };
};
