import { useEffect, useState } from "react";
import axios from "axios";
import { HealthParams } from "../dto/HealthParams";
import { healthcare } from "../routes";
import { CONNECTION_HOST } from "../environment-variables";

export const usePostHealthParams = () => {
  const [healthParams, setHealthParams] = useState<HealthParams | null>(null);
  const [healthRequestId, setHealthRequestId] = useState<number | null>(null);

  useEffect(() => {
    if (!healthParams) return;
    const getHealthRequestId = async (props: HealthParams) => {
      try {
        const response = (await axios.post(CONNECTION_HOST + healthcare, props))
          .data as number;
        setHealthRequestId(response);
      } catch (e) {
        console.error(e.response);
      }
    };
    getHealthRequestId(healthParams);
  }, [healthParams]);

  return {
    healthRequestId,
    setParams: (params: HealthParams) => setHealthParams(params),
  };
};
