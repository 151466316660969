import {useEffect, useState} from "react";
import {admin, vehicleRoute} from "../routes";
import {axios} from "../axios";
import {VehicleDto} from "../dto/VehicleDto";
import {CONNECTION_HOST} from "../environment-variables";

export const useDeleteVehicle = () => {
  const [error, setError] = useState<{ message: string } | null>(null);
  const [vehicle, setVehicle] = useState<VehicleDto | null>(null);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  useEffect(() => {
    if (!vehicle) return;
    const deleteVehicle = async (vehicle: VehicleDto) => {
      setIsDeleting(true);
      try {
        await axios.delete(
            CONNECTION_HOST + admin + vehicleRoute + `/${vehicle.id}`,
        );
      } catch (e) {
        setError(e);
        console.error(e);
      } finally {
        setIsDeleting(false);
      }
    };
    deleteVehicle(vehicle);
  }, [vehicle]);

  return {
    isDeleting,
    error,
    deleteVehicle: (vehicle: VehicleDto) => setVehicle(vehicle),
  };
};
