import React, { ReactElement, useEffect } from "react";
import { Formik } from "formik";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { results } from "./routes";
import { carLiability } from "../routes";
import { CarLiabilitysecondStepSchema } from "../validation-schema/car-liability-second-step.schema";

import { Box } from "../ui/box";
import { SecondStepHeader } from "./ui/second-step-header";
import { SecondStepForm, Step2Values } from "./ui/second-step-form";
import { Container } from "../ui/container";
import { gtagMTPLResult } from "../core/gtag";
import { usePostLiabilityData } from "../api/vehicle-insurance/liability/PostLiabilityRequest.api";
import toVehicleLiabilityConverter from "./converter/vehicle-liability-converter";
import { VehicleFullLiabilityParams } from "../entities/vehicle.insurance/VehicleFullLiabilityParams";

const CarLiabilitySecondStepPageView = ({
  history,
}: RouteComponentProps): ReactElement => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const state: any = history.location.state || {};

  const initialValues = {
    personal: !!(
      state &&
      state.personalInsurance &&
      state.personalInsurance.personalInsuranceCoverage
    ),
    personalInsuranceCoverage:
      state && state.personalInsurance
        ? state.personalInsurance.personalInsuranceCoverage
        : undefined,
    personalNumber:
      state && state.personalInsurance
        ? state.personalInsurance.personalNumber
        : undefined,
    voluntary: !!(state && state.voluntaryLiabilityInsurance),
    voluntaryLiabilityInsurance:
      state && state.voluntaryLiabilityInsurance
        ? state.voluntaryLiabilityInsurance.voluntaryLiabilityCoverage
        : undefined,
  };

  const {
    insurancePackageRequestId,
    setLiabilityRequestParams,
  } = usePostLiabilityData();

  const onSubmit = (values: Step2Values): void => {
    gtagMTPLResult(values.personal, values.voluntary);
    const vehicleFullLiabilityParams: VehicleFullLiabilityParams = {
      vehicleCivilLiabilityParams: {
        ...state,
      },
      vehiclePersonalAccidentParams: {
        personalInsuranceCoverage: values.personalInsuranceCoverage,
        personalNumber: values.personalNumber,
      },
      vehicleVoluntaryCivilLiabilityParams: {
        voluntaryLiabilityCoverage: values.voluntaryLiabilityInsurance,
      },
    };
    setLiabilityRequestParams(
      toVehicleLiabilityConverter(vehicleFullLiabilityParams),
    );
  };

  useEffect(() => {
    if (insurancePackageRequestId) {
      history.push(`${results}/${insurancePackageRequestId}`);
    }
  }, [insurancePackageRequestId]);

  return (
    <Box minHeight="100vh">
      <SecondStepHeader backRoute={carLiability} />

      <Container>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={CarLiabilitysecondStepSchema}
          component={SecondStepForm}
        />
      </Container>
    </Box>
  );
};

export const CarLiabilitySecondStepPage = withRouter(
  CarLiabilitySecondStepPageView,
);
