import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import MaterialTable from "material-table";
import { AddBox, Edit } from "@material-ui/icons";
import { t } from "../i18n/i18n";
import { UserPopup } from "./UserPopup";
import { UserDto } from "../dto/UserDto";
import {
  tableIcons,
  tableLocalization,
  useAdminTableStyles,
} from "./admin-table-properties";
import { useGetUsers } from "../api/use-get-users";
import { useSaveAdminUser } from "../api/save-admin-user";

export const UserList = () => {
  const classes = useAdminTableStyles();
  const columns = [
    { title: t("admin.id"), field: "id", emptyValue: "-" },
    { title: t("general.email"), field: "email", emptyValue: "-" },
    { title: t("general.firstName"), field: "firstName", emptyValue: "-" },
    { title: t("general.lastName"), field: "lastName", emptyValue: "-" },
    { title: t("general.phone"), field: "phone", emptyValue: "-" },
    { title: t("admin.role"), field: "role", emptyValue: "-" },
  ];
  const [userDialogOpen, setUserDialogOpen] = useState(false);
  const [userDto, setUserDto] = useState<UserDto | null>(null);
  const [users, triggerUpdatingUsers] = useGetUsers();
  const { isSaving, saveAdminUser } = useSaveAdminUser();

  useEffect(() => {
    if (!isSaving) triggerUpdatingUsers();
  }, [isSaving]);

  const handleOpenUserAddForm = () => {
    setUserDialogOpen(true);
  };

  const handleOpenUserEditForm = (user: UserDto) => {
    setUserDto(user);
    setUserDialogOpen(true);
  };

  const handleCloseUserForm = () => {
    setUserDto(null);
    setUserDialogOpen(false);
  };

  return (
    <>
      <UserPopup
        isPopupOpen={userDialogOpen}
        closePopup={handleCloseUserForm}
        savePopup={saveAdminUser}
        user={userDto}
      />

      <Paper className={`${classes.root} ${classes.tableWrapper}`}>
        <MaterialTable
          title={t("admin.userList")}
          columns={columns}
          data={users}
          icons={tableIcons}
          actions={[
            {
              icon: AddBox,
              tooltip: t("general.add"),
              isFreeAction: true,
              onClick: handleOpenUserAddForm,
            },
            {
              icon: Edit,
              tooltip: t("general.edit"),
              onClick: (event, rowData) =>
                handleOpenUserEditForm(rowData as UserDto),
            },
          ]}
          localization={tableLocalization}
        />
      </Paper>
    </>
  );
};
