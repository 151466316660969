import { useEffect, useState } from "react";
import axios from "axios";
import { LoginUserDto } from "../dto/LoginUserDto";
import { useHistory } from "react-router-dom";
import { admin, login } from "../routes";
import {CONNECTION_HOST} from "../environment-variables";

export const useLogin = () => {
  const history = useHistory();
  const [token, setToken] = useState<string>("");
  const [user, setUser] = useState<LoginUserDto | null>(null);
  useEffect(() => {
    if (!user) return;

    const loginUser = async () => {
      try {
        let resp = await axios.post(CONNECTION_HOST + admin + login, user);
        setToken(resp.data.accessToken);
        localStorage.setItem("Bearer", resp.data.accessToken);
        history.push(admin);
      } catch (e) {
        console.error(e);
        alert("Incorrect User");
      }
    };
    loginUser();
  }, [user]);
  return {
    logIn: (user: LoginUserDto): string => {
      setUser(user);
      return token;
    },
  };
};
