import React, { ReactElement } from "react"
import { Box, BoxProps } from "../box"
import { SVGIconProps } from "../../core"

interface Props {
  box?: BoxProps
  props?: SVGIconProps
}

export const SvgBurger = ({ box, props }: Props): ReactElement => (
  <Box {...box}>
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.54541 6.36355C2.54541 5.66064 3.11523 5.09082 3.81814 5.09082H24.1818C24.8847 5.09082 25.4545 5.66064 25.4545 6.36355C25.4545 7.06645 24.8847 7.63628 24.1818 7.63628H3.81814C3.11523 7.63628 2.54541 7.06645 2.54541 6.36355ZM2.54541 21.6363C2.54541 20.9333 3.11523 20.3635 3.81814 20.3635H24.1818C24.8847 20.3635 25.4545 20.9333 25.4545 21.6363C25.4545 22.3392 24.8847 22.909 24.1818 22.909H3.81814C3.11523 22.909 2.54541 22.3392 2.54541 21.6363ZM3.81814 12.7272C3.11523 12.7272 2.54541 13.297 2.54541 13.9999C2.54541 14.7028 3.11523 15.2726 3.81814 15.2726H24.1818C24.8847 15.2726 25.4545 14.7028 25.4545 13.9999C25.4545 13.297 24.8847 12.7272 24.1818 12.7272H3.81814Z"
        fill="#308FFF"
      />
    </svg>
  </Box>
)
