import React, { ReactElement } from "react"

export const SvgStar = (): ReactElement => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.20442 7.01716L7.54105 4.95842L6.87782 7.01667L4.59846 7.04434L6.42765 8.4714L5.78633 10.625L7.54148 9.32062L9.2957 10.6247L8.65461 8.47188L10.4838 7.04482L8.20442 7.01716ZM13.2792 5.72271C13.6306 5.72699 13.7761 6.19632 13.4942 6.41597L13.2687 6.59194L10.2182 8.97174L11.4351 13.0579C11.5397 13.4096 11.1592 13.6993 10.8724 13.4862L7.54137 11.01L4.46049 13.2996L4.20993 13.4858C3.92355 13.6988 3.54264 13.4086 3.64719 13.0574L4.86404 8.97126L1.81366 6.5915L1.58804 6.41549C1.30622 6.19584 1.45168 5.72652 1.80305 5.72223L5.88633 5.67268L7.19318 1.61699C7.30591 1.26822 7.77684 1.26822 7.88911 1.61747L9.196 5.67316L13.2792 5.72271Z"
      fill="#13D5A6"
    />
  </svg>
)
