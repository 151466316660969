import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
}));

export const Healthcare = () => {
  const classes = useStyles();
  return <>Healthcare</>;
};
