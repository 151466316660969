import React, { ReactElement, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import get from "lodash/get";
import { form } from "./routes";
import { TypeInsuranceEnum } from "../core/enums";

import { Box } from "../ui/box";
import { HeaderPage } from "../ui/header.page";
import { TariffCards } from "../ui/tariff-cards/tariff-cards";
import { Footer } from "../ui/footer/footer";
import { ResultsTitle } from "./ui/results-title";
import { ResultsHeader } from "./ui/results-header";
import { Container } from "../ui/container";
import { HorizontalLine } from "../ui/footer/horizontal-line";
import { InsuranceVariant } from "../core";
import { useGetLiabilityCalculations } from "../api/vehicle-insurance/liability/GetLiabilityCalculation.api";
import { PurposeType } from "../entities/vehicle.insurance/enums";
import {
  getPassengers,
  getPurpose,
  getSeats,
  getVoluntaryCoverage,
} from "./get-result-header";

export const CarLiabilityResultsPage = (): ReactElement => {
  const history = useHistory();
  const state = get(history, "location.state") || {};
  const {
    insurancePackageWithCalculationsDto,
    setInsurancePackageRequestId,
    isSpinner,
  } = useGetLiabilityCalculations();

  const { id } = useParams();
  useEffect(() => {
    setInsurancePackageRequestId(id);
  }, [id]);

  const [purpose, setPurpose] = useState<PurposeType>();
  const [seats, setSeats] = useState<number>(0);
  const [tonnage, setTonnage] = useState<number>(0);
  const [passengers, setPassengers] = useState<number>();
  const [voluntaryCoverage, setVoluntaryCoverage] = useState<number>();

  useEffect(() => {
    if (insurancePackageWithCalculationsDto) {
      setPurpose(getPurpose({ insurancePackageWithCalculationsDto }));
      setSeats(getSeats({ insurancePackageWithCalculationsDto }));
      setTonnage(6);
      setPassengers(getPassengers({ insurancePackageWithCalculationsDto }));
      setVoluntaryCoverage(
        getVoluntaryCoverage({ insurancePackageWithCalculationsDto }),
      );
    }
  }, [insurancePackageWithCalculationsDto]);

  const selectTariff = (
    total: number,
    companyName: string,
    variant: InsuranceVariant,
  ): void =>
    history.push({
      pathname: form,
      state: { ...state, total, companyName, variant },
    });

  return (
    <Box>
      <Container>
        <HeaderPage />
      </Container>
      <ResultsTitle />

      <ResultsHeader
        purpose={purpose}
        seats={String(seats)}
        personalNumber={String(passengers)}
        voluntaryLiabilityInsurance={String(voluntaryCoverage)}
        tonnage={String(tonnage)}
      />

      <Container>
        <TariffCards
          selectTariff={selectTariff}
          typeInsurance={TypeInsuranceEnum.LIABILITY}
          mx={["26px", "26px", "26px", "26px", "100px", "100px", "180px"]}
          insurancePackageWithCalculationsDto={
            insurancePackageWithCalculationsDto
          }
          isSpinner={isSpinner}
        />
      </Container>

      <HorizontalLine mt={["97px", "97px", "100px"]} />

      <Container>
        <Footer borderTop="none" />
      </Container>
    </Box>
  );
};
