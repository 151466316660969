import React, { Fragment, ReactElement } from "react";
import { Formik } from "formik";
import { LoginFormContent } from "./LoginFormContent";
import { LoginSchema } from "../validation-schema/login-schema";
import { LoginUserDto } from "../dto/LoginUserDto";
import { useHistory } from "react-router-dom";
import { admin } from "../routes";

export const LoginFormView = (): ReactElement => {
  const initialValues: LoginUserDto = {
    email: "",
    password: "",
  };
  const history = useHistory();

  if (localStorage.getItem("Bearer")) {
    history.push(admin);
  }
  const handleSubmit = (): void => {};

  return (
    <Fragment>
      <Formik
        component={(): ReactElement => <LoginFormContent />}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={LoginSchema}
        validateOnBlur={false}
        validateOnChange={false}
      />
    </Fragment>
  );
};
