export const root = "/";
export const carLiability = "/car-liability";
export const comprehensiveCar = "/comprehensive-car";
export const healthcare = "/healthcare";
export const feedback = "/feedback";
export const sorry = "/sorry";
export const payment = "/payment";
export const terms = "/terms";
export const privacyPolicy = "/privacy-policy";
export const login = "/login";
export const admin = "/admin";
export const userRoute = "/users";
export const vehicleRoute = "/vehicles";
export const brandRoute = "/brands";
export const modelsRoute = "/models";
