import React, { forwardRef } from "react";
import { Icons } from "material-table";
import {
  AddBox,
  ArrowUpward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@material-ui/icons";
import { t } from "../i18n/i18n";
import { makeStyles } from "@material-ui/core";

export const useAdminTableStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: "auto",
  },
}));

export const tableIcons: Icons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export const tableLocalization = {
  body: {
    emptyDataSourceMessage: t("general.emptyData"),
    addTooltip: t("general.add"),
    deleteTooltip: t("general.delete"),
    editTooltip: t("general.edit"),
    filterRow: { filterTooltip: t("general.filter") },
    editRow: {
      deleteText: t("general.deleteText"),
      cancelTooltip: t("general.cancel"),
      saveTooltip: t("general.save"),
    },
  },
  pagination: {
    labelRowsSelect: t("general.paginationRows"),
    labelRowsPerPage: t("general.paginationRowsPerPage"),
    firstTooltip: t("general.paginationFirstPage"),
    previousTooltip: t("general.paginationPreviousPage"),
    nextTooltip: t("general.paginationNextPage"),
    lastTooltip: t("general.paginationLastPage"),
  },
  toolbar: {
    searchTooltip: t("general.newSearch"),
    searchPlaceholder: t("general.newSearch"),
  },
};
