import {
  InsuranceType,
  PurposeType,
} from "../entities/vehicle.insurance/enums";
import { InsurancePackageItemDto } from "../dto/insurance/InsurancePackageItemDto";
import { InsurancePackageWithCalculationsDto } from "../dto/insurance/InsurancePackageWithCalculationsDto";

export const getPurpose = ({
  insurancePackageWithCalculationsDto,
}: {
  insurancePackageWithCalculationsDto: InsurancePackageWithCalculationsDto;
}): PurposeType => {
  return getCCL({ insurancePackageWithCalculationsDto }).purpose;
};

export const getSeats = ({
  insurancePackageWithCalculationsDto,
}: {
  insurancePackageWithCalculationsDto: InsurancePackageWithCalculationsDto;
}): number => {
  return getCCL({ insurancePackageWithCalculationsDto }).seats;
};

export const getPassengers = ({
  insurancePackageWithCalculationsDto,
}: {
  insurancePackageWithCalculationsDto: InsurancePackageWithCalculationsDto;
}): number => {
  return insurancePackageWithCalculationsDto.insurancePackageItemList.filter(
    insuranceItem => insuranceItem.insuranceType === InsuranceType.CAR_CPA,
  )[0]?.passengers;
};

export const getVoluntaryCoverage = ({
  insurancePackageWithCalculationsDto,
}: {
  insurancePackageWithCalculationsDto: InsurancePackageWithCalculationsDto;
}): number => {
  return insurancePackageWithCalculationsDto.insurancePackageItemList.filter(
    insuranceItem => insuranceItem.insuranceType === InsuranceType.CAR_VCL,
  )[0]?.coverage;
};

const getCCL = ({
  insurancePackageWithCalculationsDto,
}: {
  insurancePackageWithCalculationsDto: InsurancePackageWithCalculationsDto;
}): InsurancePackageItemDto => {
  return insurancePackageWithCalculationsDto.insurancePackageItemList.filter(
    insuranceItem => insuranceItem.insuranceType === InsuranceType.CAR_CCL,
  )[0];
};
