import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { t } from "../i18n/i18n";
import { VehicleDto } from "../dto/VehicleDto";
import { useGetVehicleModels } from "../api/use-get-vehicle-models";
import { VehicleBrandDto } from "../dto/VehicleBrandDto";

export function VehiclePopup(props: {
  isPopupOpen: boolean;
  savePopup: (vehicleDto: VehicleDto) => void;
  closePopup: () => void;
  vehicle: VehicleDto | null;
  brands: VehicleBrandDto[];
}) {
  const { isPopupOpen, savePopup, closePopup, vehicle, brands } = props;

  const [vehicleId, setVehicleId] = useState<number | undefined>(undefined);
  const [brandId, setBrandId] = useState<number | null>(null);
  const [model, setModel] = useState<string>("");
  const [year, setYear] = useState<number | null>(null);
  const [price, setPrice] = useState<number | null>(null);

  const [isBrandInvalid, setIsBrandInvalid] = useState(false);
  const [isModelInvalid, setIsModelInvalid] = useState(false);
  const [isYearInputInvalid, setIsYearInputInvalid] = useState(false);
  const [isPriceInputInvalid, setIsPriceInputInvalid] = useState(false);

  const [isCreateNewModel, setIsCreateNewModel] = useState<boolean>(false);
  const modelsOfSelectedBrand = useGetVehicleModels(brandId);

  useEffect(() => {
    setVehicleId(vehicle ? vehicle.id : undefined);
    setBrandId(vehicle ? vehicle.brandId : null);
    setModel(vehicle ? vehicle.name : "");
    setYear(vehicle ? vehicle.year : null);
    setPrice(vehicle ? vehicle.price : null);
  }, [vehicle]);

  useEffect(() => {
    setIsBrandInvalid(!brandId);
    setIsModelInvalid(model === "");
    setIsYearInputInvalid(!year);
    setIsPriceInputInvalid(!price);
  }, [brandId, model, year, price]);

  const handleCancel = () => {
    setIsCreateNewModel(false);
    setVehicleId(undefined);
    setBrandId(null);
    setModel("");
    setYear(null);
    setPrice(null);

    closePopup();
  };

  const handleSave = () => {
    if (
      !isBrandInvalid &&
      !isModelInvalid &&
      !isYearInputInvalid &&
      !isPriceInputInvalid
    ) {
      const vehicleToSave: VehicleDto = {
        id: vehicleId,
        brandId: brandId ? brandId : 0,
        name: model,
        year: year ? year : 0,
        price: price ? price : 0,
      };
      savePopup(vehicleToSave);
      handleCancel();
    }
  };

  return (
    <Dialog
      open={isPopupOpen}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle id="form-dialog-title">
        {!vehicleId
          ? t("vehiclePopUp.addToCreateTitle")
          : t("vehiclePopUp.editTitle")}
      </DialogTitle>
      <DialogContent>
        <FormControl fullWidth error={isBrandInvalid} required={true}>
          <InputLabel id="brand-label">Brand</InputLabel>
          <Select
            labelId={"brand-label"}
            defaultValue={vehicle ? vehicle.brandId : ""}
            value={brandId ? brandId : ""}
            onChange={event => {
              setBrandId(event.target.value as number);
              setModel("");
            }}
          >
            {brands.map(brand => (
              <MenuItem value={brand.id} key={brand.id}>
                {brand.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            {isModelInvalid && t("vehiclePopUp.brandRequired")}
          </FormHelperText>
          <FormControlLabel
            control={
              <Checkbox
                checked={isCreateNewModel}
                onChange={event => setIsCreateNewModel(event.target.checked)}
              />
            }
            label={t("vehiclePopUp.addModelForBrand")}
          />
        </FormControl>
        {isCreateNewModel ? (
          <TextField
            fullWidth
            label={t("vehicle.model")}
            defaultValue={model}
            required={true}
            error={isModelInvalid}
            helperText={isModelInvalid && t("vehiclePopUp.modelRequired")}
            onChange={event => setModel(event.target.value)}
          />
        ) : (
          <FormControl fullWidth error={isModelInvalid} required={true}>
            <InputLabel id="model-label">{t("vehicle.model")}</InputLabel>
            <Select
              labelId={"model-label"}
              defaultValue={vehicle ? vehicle.name : ""}
              value={model ? model : ""}
              onChange={e => setModel(e.target.value as string)}
            >
              {modelsOfSelectedBrand.map(model => (
                <MenuItem value={model} key={model}>
                  {model}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {isModelInvalid && t("vehiclePopUp.modelRequired")}
            </FormHelperText>
          </FormControl>
        )}
        <TextField
          fullWidth
          label={t("vehicle.year")}
          type={"number"}
          defaultValue={year}
          required={true}
          error={isYearInputInvalid}
          helperText={isYearInputInvalid && t("vehiclePopUp.yearRequired")}
          onChange={event => setYear(parseInt(event.target.value, 10) || null)}
        />
        <TextField
          fullWidth
          label={t("vehicle.price")}
          type={"number"}
          defaultValue={price}
          required={true}
          error={isPriceInputInvalid}
          helperText={isPriceInputInvalid && t("vehiclePopUp.priceRequired")}
          onChange={event => setPrice(parseInt(event.target.value, 10) || null)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          {t("general.cancel")}
        </Button>
        <Button onClick={handleSave} color="primary">
          {!vehicleId ? t("general.create") : t("general.edit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
