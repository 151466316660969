export const CONNECTION_HOST: string | undefined =
  process.env.REACT_APP_CONNECTION_HOST;

export const checkEnvironmentParams = () => {
  const errors: string[] = [];

  if (!CONNECTION_HOST) {
    errors.push("Variable REACT_APP_CONNECTION_HOST should be defined");
  }

  if (errors.length) throw errors;
};
