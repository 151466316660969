import React, { ReactElement } from "react";
import { useMediaQuery } from "react-responsive";
import { theme } from "../../theme/theme";
import { useShowSpinner } from "../../hooks/use-spinner";
import { TypeInsuranceEnum } from "../../core/enums";
import { Box, BoxProps } from "../../ui/box";
import { TariffCard } from "../../ui/tariff-card";
import { Spinner } from "../../ui/spinner";
import { companies, getHealthFields } from "../health-tariffs";
import { t } from "../../i18n/i18n";
import { HealthCard } from "../health-card";

type Props = BoxProps & {
  tariffs: HealthCard[] | null;
  typeInsurance: TypeInsuranceEnum;
  minHeight?: string | string[];
  selectTariff?: (total: number) => void;
};

export const HealthTariffCards = ({
  tariffs,
  minHeight,
  typeInsurance,
  selectTariff,
  ...rest
}: Props): ReactElement => {
  const isMobile = useMediaQuery({
    maxWidth: theme("breakpoints.3"),
  });
  const isLoading = useShowSpinner();

  return isLoading ? (
    <Spinner />
  ) : (
    <Box
      display="flex"
      mx={isMobile ? "26px" : "180px"}
      justifyContent="center"
      flexWrap="wrap"
      {...rest}
    >
      {tariffs &&
        tariffs.length > 0 &&
        tariffs.map((tariff: HealthCard, index: number) => {
          const fields = getHealthFields(tariff.benefits);

          return (
            <TariffCard
              key={index}
              typeInsurance={typeInsurance}
              imagePath={companies[tariff.company].image}
              rating={Number(tariff.rating)}
              fields={fields}
              total={tariff.price}
              minHeight={minHeight}
              selectTariff={selectTariff}
              years={tariff.years}
              packageName={t("health.healthCards." + tariff.name)}
              company={tariff.company}
            />
          );
        })}
    </Box>
  );
};
