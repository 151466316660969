import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import MaterialTable from "material-table";
import { t } from "../i18n/i18n";
import { AddBox, Delete, Edit } from "@material-ui/icons";
import {
  tableIcons,
  tableLocalization,
  useAdminTableStyles,
} from "./admin-table-properties";
import { useGetVehicles } from "../api/use-get-vehicles";
import { VehicleDto } from "../dto/VehicleDto";
import { VehiclePopup } from "./vehicle-popup";
import { VehicleBrandDto } from "../dto/VehicleBrandDto";
import { useGetVehicleBrands } from "../api/use-get-vehicle-brends";
import { useSaveVehicle } from "../api/save-vehicle";
import { useDeleteVehicle } from "../api/delete-vehicle";

export const VehicleList = () => {
  const classes = useAdminTableStyles();
  const [vehicles, updateVehicles] = useGetVehicles();
  const columns = [
    { title: t("admin.id"), field: "id", emptyValue: "-" },
    { title: t("vehicle.brand"), field: "brandName", emptyValue: "-" },
    { title: t("vehicle.model"), field: "name", emptyValue: "-" },
    { title: t("vehicle.year"), field: "year", emptyValue: "-" },
    { title: t("vehicle.price"), field: "price", emptyValue: "-" },
  ];
  const [vehicleDialogOpen, setVehicleDialogOpen] = useState(false);
  const [vehicle, setVehicle] = useState<VehicleDto | null>(null);
  const brands: VehicleBrandDto[] = useGetVehicleBrands();
  const { isSaving, saveVehicle } = useSaveVehicle();
  const { isDeleting, deleteVehicle } = useDeleteVehicle();

  const handleOpenVehicleAddForm = () => {
    setVehicleDialogOpen(true);
  };

  useEffect(() => {
    if (!isSaving) updateVehicles();
  }, [isSaving]);

  useEffect(() => {
    if (!isDeleting) updateVehicles();
  }, [isDeleting]);

  const handleOpenVehicleEditForm = (vehicle: VehicleDto) => {
    setVehicle(vehicle);
    setVehicleDialogOpen(true);
  };

  const handleCloseVehicleForm = () => {
    setVehicle(null);
    setVehicleDialogOpen(false);
  };

  const handleSaveVehicleForm = (vehicleDto: VehicleDto) => {
    saveVehicle(vehicleDto);
    handleCloseVehicleForm();
  };

  const handleDeleteVehicle = (vehicle: VehicleDto) => {
    deleteVehicle(vehicle);
  };

  return (
    <>
      <VehiclePopup
        isPopupOpen={vehicleDialogOpen}
        savePopup={handleSaveVehicleForm}
        closePopup={handleCloseVehicleForm}
        vehicle={vehicle}
        brands={brands}
      />

      <Paper className={`${classes.root} ${classes.tableWrapper}`}>
        <MaterialTable
          title={t("admin.vehicleList")}
          columns={columns}
          data={vehicles}
          icons={tableIcons}
          actions={[
            {
              icon: AddBox,
              tooltip: t("general.add"),
              isFreeAction: true,
              onClick: handleOpenVehicleAddForm,
            },
            {
              icon: Edit,
              tooltip: t("general.edit"),
              onClick: (event, rowData) =>
                handleOpenVehicleEditForm(rowData as VehicleDto),
            },
            {
              icon: Delete,
              tooltip: t("general.delete"),
              onClick: (event, rowData) =>
                handleDeleteVehicle(rowData as VehicleDto),
            },
          ]}
          localization={tableLocalization}
        />
      </Paper>
    </>
  );
};
