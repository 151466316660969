import React, { ReactElement, useEffect } from "react";
import { Form, Formik } from "formik";
import { SliderInput } from "./slider-input";
import { Button } from "../../ui/buttons/button";
import { t } from "../../i18n/i18n";
import { Box } from "../../ui/box";
import { MAX_RANGE, MIN_RANGE } from "../../core/constants";
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";
import { HealthHeaderSchema } from "../../validation-schema/health-header.schema";
import { InputTypeDate } from "../../ui/inputs/input-type-date";
import { gtagHealthHeader } from "../../core/gtag";
import { HealthParams } from "../../dto/HealthParams";
import { usePostHealthParams } from "../../api/use-post-health-params";
import { healthcare } from "../../routes";

type Props = RouteComponentProps & {
  isMobile: boolean;
};

interface HealthFormProps {
  min: number;
  max: number;
  date: string;
}

export const HealthHeaderFields = withRouter(
  ({ isMobile }: Props): ReactElement => {
    const maxTotalCoverage = MAX_RANGE;

    const initialValues = {
      min: MIN_RANGE,
      max: maxTotalCoverage,
      date: "",
    };

    const history = useHistory();

    const { healthRequestId, setParams } = usePostHealthParams();
    const handleSubmit = (values: HealthFormProps): void => {
      const healthVars: HealthParams = {
        birthDate: values.date,
        minCoverage: values.min,
        maxCoverage: values.max,
      };
      setParams(healthVars);
      gtagHealthHeader(values.date);
    };

    useEffect(() => {
      if (healthRequestId)
        history.push(healthcare + "/result/" + healthRequestId);
    }, [healthRequestId]);

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={HealthHeaderSchema}
        validateOnChange={false}
        validateOnBlur={false}
        component={({ values, setFieldValue }): ReactElement => (
          <Form>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="end"
              width="100%"
            >
              <SliderInput
                minName="min"
                maxName="max"
                minValue={MIN_RANGE}
                maxValue={maxTotalCoverage}
                values={values}
                setFieldValue={setFieldValue}
              />

              <InputTypeDate
                name="date"
                setFieldValue={setFieldValue}
                placeholder={t("health.birthDate")}
                isDateOfBirth
                value={values.date}
              />

              <Button
                text={t("general.continue")}
                width={isMobile ? "100%" : "185px"}
                type="submit"
                ml="auto"
                mt="15px"
              />
            </Box>
          </Form>
        )}
      />
    );
  },
);
