import React from "react";
import { NavLink, Redirect, useHistory } from "react-router-dom";
import { Route, Switch } from "react-router";
import { AppBar, Button, Toolbar, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { admin, login} from "../routes";
import { UserList } from "./UserList";
import { Healthcare } from "./healthcare";
import { CarLiability } from "./car-liability";
import { ComprehensiveCar } from "./comprehensive-car";
import { t } from "../i18n/i18n";
import { VehicleList } from "./VehicleList";

const useStyles = makeStyles(theme => ({
  link: {
    flexGrow: 1,
  },
  title: {
    background: "white",
    position: "static",
  },
}));

export const AdminNavigation = () => {
  const classes = useStyles();
  const history = useHistory();

  function logout() {
    localStorage.removeItem("Bearer");
    history.push(admin + login);
  }

  return (
    <>
      <AppBar className={classes.title}>
        <Toolbar>
          <Typography className={classes.link} variant={"h6"}>
            <NavLink to={admin + "/user-list"}>{t("admin.userList")}</NavLink>
          </Typography>
          <Typography className={classes.link} variant={"h6"}>
            <NavLink to={admin + "/vehicle-list"}>
              {t("admin.vehicleList")}
            </NavLink>
          </Typography>
          <Typography className={classes.link} variant={"h6"}>
            <NavLink to={admin + "/car-liability"}>
              {t("admin.carLiabilityInsurance")}
            </NavLink>
          </Typography>
          <Typography className={classes.link} variant={"h6"}>
            <NavLink to={admin + "/comprehensive-car"}>
              {t("admin.comprehensiveCarInsurance")}
            </NavLink>
          </Typography>
          <Typography className={classes.link} variant={"h6"}>
            <NavLink to={admin + "/healthcare"}>
              {t("admin.healthInsurance")}
            </NavLink>
          </Typography>
          <Button onClick={() => logout()}>{t("admin.logout")}</Button>
        </Toolbar>
      </AppBar>
      <Switch>
        <Route path={admin + "/user-list"} children={<UserList />} exact />
        <Route
          path={admin + "/vehicle-list"}
          children={<VehicleList />}
          exact
        />
        <Route
          path={admin + "/car-liability"}
          children={<CarLiability />}
          exact
        />
        <Route
          path={admin + "/comprehensive-car"}
          children={<ComprehensiveCar />}
          exact
        />
        <Route path={admin + "/healthcare"} children={<Healthcare />} exact />
        <Route path={[admin, admin + "/*"]}>
          <Redirect to={admin + "/user-list"} />
        </Route>
      </Switch>
    </>
  );
};
