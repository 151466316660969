import { CompanyTariff } from "./core";
import { TypeInsuranceEnum } from "./core/enums";
import { formatPrice } from "./core/convert-price";
import { t } from "./i18n/i18n";
import { CalculationDto } from "./dto/insurance/calculation/CalculationCardDto";

interface AdditionalField {
  carLiability?: boolean;
  voluntaryLiabilityInsurance?: string;
  personalInsurance?: string;
}

export const companiesTariffs: CompanyTariff[] = [
  {
    name: "Baoviet Insurance",
    deviationRate: 0.96,
    rating: 4.5,
    image: "baoviet.png",
  },
  {
    name: "PVI Insurance",
    deviationRate: 0.9,
    rating: 4.8,
    image: "pvi.png",
  },
  {
    name: "PTI Insurance",
    deviationRate: 0.88,
    rating: 4.9,
    image: "pti.png",
  },
  {
    name: "PJICO",
    deviationRate: 0.97,
    rating: 4.5,
    image: "pjico.png",
  },
  {
    name: "BaoMinh",
    deviationRate: 0.98,
    rating: 4.5,
    image: "baominh.png",
  },
  {
    name: "BIC",
    deviationRate: 0.92,
    rating: 4,
    image: "bic.png",
  },
  {
    name: "VASS Assurance Corp.",
    deviationRate: 0.97,
    rating: 4.5,
    image: "vass.png",
  },
  {
    name: "VietinBank Insurance",
    deviationRate: 0.98,
    rating: 4.5,
    image: "vietinbank.png",
  },
];

export const companiesWithVoucher: string[] = [
  "VietinBank Insurance",
  "PVI Insurance",
  "PJICO",
];

type TariffsFieldsParams = {
  typeInsurance: TypeInsuranceEnum;
  liabilityData?: CalculationDto;
  additionalField?: AdditionalField;
  deductible?: number;
};

export const getTariffFields = ({
  typeInsurance,
  liabilityData,
  additionalField,
  deductible,
}: TariffsFieldsParams) => {
  const fields = {};

  if (typeInsurance === TypeInsuranceEnum.LIABILITY) {
    Object.assign(
      fields,
      {
        "tariffCard.propertyDamageLimit": formatPrice(
          Number(liabilityData?.propertyDamageLimit),
        ),
        "tariffCard.bodilyInjuryLimit": formatPrice(
          Number(liabilityData?.bodilyInjuryLimit),
        ),
      },
      {
        "tariffCard.voluntaryCivilLiabilityInsurance": t("general.price", {
          0: formatPrice(
            Number(liabilityData?.voluntaryCivilLiabilityInsurance),
          ),
        }),
      },
      {
        "tariffCard.personalAccidentInsurance": t("general.price", {
          0: formatPrice(Number(liabilityData?.personalAccidentInsurance)),
        }),
      },
    );
  }

  if (typeInsurance === TypeInsuranceEnum.COMPREHENSIVE) {
    Object.assign(
      fields,
      {
        "tariffCard.coverage": "800,000,000 VND",
        "tariffCard.deductible": t("general.price", {
          0: deductible || 0,
        }),
      },
      additionalField?.carLiability && {
        "tariffCard.carLiability": "100,000,000 VND",
      },
      additionalField?.voluntaryLiabilityInsurance && {
        "tariffCard.voluntaryCivilLiabilityInsurance": t("general.price", {
          0: formatPrice(Number(additionalField.voluntaryLiabilityInsurance)),
        }),
      },
      additionalField?.personalInsurance && {
        "tariffCard.personalAccidentInsurance": t("general.price", {
          0: formatPrice(Number(additionalField?.personalInsurance)),
        }),
      },
    );
  }

  if (typeInsurance === TypeInsuranceEnum.HEALTH) {
    return {};
  }

  return fields;
};
