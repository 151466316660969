import { useContext, useEffect, useState } from "react";
import { HealthContext } from "../hooks/HealthProvider";
import axios from "axios";
import { healthcare } from "../routes";
import { HealthCompanyTariffsDto } from "../dto/HealthCompanyTariffsDto";
import { CONNECTION_HOST } from "../environment-variables";

export const useHealthById = () => {
  const {
    healthCards: { cards },
  } = useContext(HealthContext);
  const [id, setId] = useState<number | null>(null);
  const [resp, setResp] = useState<HealthCompanyTariffsDto | null>(null);
  useEffect(() => {
    const getCards = async () => {
      if (cards === null && id !== null) {
        try {
          const axiosResponse = await axios.get(
            CONNECTION_HOST + healthcare + "/" + id,
          );
          const response: HealthCompanyTariffsDto = axiosResponse.data;
          setResp(response);
        } catch (e) {
          console.error(e.response);
        }
      }
    };
    getCards();
  }, [id]);
  return { resp, setId };
};
