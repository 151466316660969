import { useEffect, useState } from "react";
import { axios } from "../axios";
import { CONNECTION_HOST } from "../environment-variables";
import { admin, userRoute } from "../routes";
import { UserDto } from "../dto/UserDto";

export const useGetUsers = (): [UserDto[], () => void] => {
  const [users, setUsers] = useState<UserDto[]>([]);
  const [refreshObject, setRefreshObject] = useState<object>({});

  useEffect(() => {
    const getUsers = async () => {
      try {
        let resp = await axios.get(CONNECTION_HOST + admin + userRoute);
        setUsers(resp.data);
      } catch (e) {
        console.error(e.response);
        alert(e);
      }
    };
    getUsers();
  }, [refreshObject]);
  return [users, () => setRefreshObject({})];
};