import {useEffect, useState} from "react";
import {axios} from "../axios";
import {CONNECTION_HOST} from "../environment-variables";
import {modelsRoute, vehicleRoute} from "../routes";

export const useGetVehicleModels = (brand: number | null): string[] => {
  const [models, setModels] = useState<string[]>([]);

  useEffect(() => {
    if (!brand) {
      setModels([]);
      return;
    }
    const getModels = async () => {
      if (!brand) return;
      try {
        let resp = await axios.get(
          CONNECTION_HOST + vehicleRoute + `/${brand}` + modelsRoute,
        );
        setModels(resp.data);
      } catch (e) {
        console.error(e);
      }
    };
    getModels();
  }, [brand]);
  return models;
};
