import { UserDto } from "../dto/UserDto";
import { useEffect, useState } from "react";
import { admin, userRoute } from "../routes";
import { axios } from "../axios";
import { CONNECTION_HOST } from "../environment-variables";

export const useSaveAdminUser = () => {
  const [error, setError] = useState<{ message: string } | null>(null);
  const [user, setUser] = useState<UserDto | null>(null);
  const [updatedUser, setUpdatedUser] = useState<UserDto | null>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    if (!user) return;    
    const createUser = async (user: UserDto) => {
      setIsSaving(true);
      try {
        let resp;
        if (user.id) {
          resp = await axios.put(CONNECTION_HOST + admin + userRoute, user);
        } else {
          resp = await axios.post(CONNECTION_HOST + admin + userRoute, user);
        }
        setUpdatedUser(resp.data);
      } catch (e) {
        setError(e.response);
        console.error(e.response);
      } finally {
        setIsSaving(false);
      }
    };
    createUser(user);
  }, [user]);

  return {
    isSaving,
    error,
    updatedUser,
    saveAdminUser: (user: UserDto) => setUser(user),
  };
};
