import { useEffect, useState } from "react";
import axios from "axios";
import { VehicleFullLiabilityDto } from "../../../dto/insurance/VehicleInsuranceDtos";
import { liabilityController } from "../vehicle-insurance-routes";

export const usePostLiabilityData = () => {
  const [
    liabilityData,
    setLiabilityData,
  ] = useState<VehicleFullLiabilityDto | null>(null);
  const [insurancePackageRequestId, setInsurancePackageRequestId] = useState<
    number | null
  >(null);

  useEffect(() => {
    if (!liabilityData) return;
    const getInsurancePackageRequestId = async (
      params: VehicleFullLiabilityDto,
    ) => {
      try {
        const response = (await axios.post(liabilityController, params))
          .data as number;
        setInsurancePackageRequestId(response);
      } catch (e) {
        console.error(e.response);
      }
    };
    getInsurancePackageRequestId(liabilityData);
  }, [liabilityData]);

  return {
    insurancePackageRequestId,
    setLiabilityRequestParams: (params: VehicleFullLiabilityDto) =>
      setLiabilityData(params),
  };
};
