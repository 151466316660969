import React, { createContext, useEffect, useState } from "react";
import { HealthParams } from "../dto/HealthParams";
import { HealthCard } from "../healthcare/health-card";
import { useHealthById } from "../api/use-health-by-id";
import { healthcare } from "../routes";
import { useHistory } from "react-router-dom";

export const HealthContext = createContext<HealthContextType>({
  healthCards: {
    cards: null,
    params: {
      birthDate: "",
      maxCoverage: 0,
      minCoverage: 0,
    },
    fetch: () => {},
  },
});

export type HealthContextType = {
  healthCards: {
    cards: HealthCard[] | null;
    params: HealthParams;
    fetch: (id: number) => void;
  };
};

type MyProps = {};

export const HealthContextProvider: React.FunctionComponent<MyProps> = props => {
  const [cards, setCards] = useState<HealthCard[] | null>(null);
  const [params, setParams] = useState<HealthParams>({
    birthDate: "",
    maxCoverage: 0,
    minCoverage: 0,
  });
  const history = useHistory();
  const { setId, resp } = useHealthById();
  const fetch=(id:number)=>{
    setCards(null);
    setParams({
      birthDate: "",
      maxCoverage: 0,
      minCoverage: 0,
    });
    setId(id);
  }
  const { children } = props;
  useEffect(() => {
    if (resp !== null) {
      setParams({
        birthDate: resp.item.birthDate,
        minCoverage: resp.item.coverageFrom,
        maxCoverage: resp.item.coverageTo,
      });
      setCards(resp.cards);
      resp.item.birthDate === "" &&
        resp.item.coverageFrom === 0 &&
        resp.item.coverageTo === 0 &&
        history.push(healthcare);
    }
  }, [resp]);
  return (
    <HealthContext.Provider
      value={{
        healthCards: {
          cards,
          params,
          fetch: fetch
        },
      }}
    >
      {children}
    </HealthContext.Provider>
  );
};
