import React, { ReactElement } from "react"
import { Box } from "../box"

export const SvgLocation = (): ReactElement => (
  <Box minWidth="22px">
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.2817 13.0395C5.47626 13.3144 5.69014 13.5746 5.92137 13.8182L10.227 19.0599C10.6269 19.5467 11.3719 19.5469 11.7721 19.0604L16.1295 13.7625C16.3215 13.5558 16.5011 13.3375 16.6673 13.1087L16.7566 13H16.7446C17.5355 11.8662 17.9993 10.4873 17.9993 9C17.9993 5.13401 14.8653 2 10.9993 2C7.13328 2 3.99927 5.13401 3.99927 9C3.99927 10.4873 4.46308 11.8662 5.25396 13H5.24927L5.2817 13.0395ZM13.9993 9C13.9993 10.6568 12.6561 12 10.9993 12C9.34242 12 7.99927 10.6568 7.99927 9C7.99927 7.34315 9.34242 6 10.9993 6C12.6561 6 13.9993 7.34315 13.9993 9Z"
        fill="#13D5A6"
      />
    </svg>
  </Box>
)
