import { useEffect, useState } from "react";
import { VehicleDto } from "../dto/VehicleDto";
import { axios } from "../axios";
import { vehicleRoute } from "../routes";
import { CONNECTION_HOST } from "../environment-variables";

export const useGetVehicles = (): [VehicleDto[], () => void] => {
  const [vehicles, setVehicles] = useState<VehicleDto[]>([]);
  const [refreshObject, setRefreshObject] = useState<object>({});

  useEffect(() => {
    const getVehicles = async () => {
      try {
        let resp = await axios.get(CONNECTION_HOST + vehicleRoute);
        setVehicles(resp.data);
      } catch (e) {
        console.error(e);
      }
    };
    getVehicles();
  }, [refreshObject]);
  return [vehicles, () => setRefreshObject({})];
};
