import { formatPrice } from "../core/convert-price";
import { t } from "../i18n/i18n";

interface Companies {
  [name: string]: {
    image: string;
  };
}

export const companies: Companies = {
  "PTI Insurance": {
    image: "pti.png",
  },
  "VietinBank Insurance": {
    image: "vietinbank.png",
  },
  "Bao Long Insurance": {
    image: "baolong.png",
  },
  "Baoviet Insurance": {
    image: "baoviet.png",
  },
};

export const getHealthFields = (benefits: {
  [key: string]: number;
}): { [key: string]: string } => {
  const fields: { [key: string]: string } = {};

  Object.keys(benefits).forEach((key: string): void => {
    const keyI18n=t("health.healthCards."+key)
    fields[keyI18n] = t("general.price", {
      0: formatPrice(benefits[key]),
    });
  });

  return fields;
};